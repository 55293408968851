import type { CartItemVariant } from '#types/cart'

/**
 * Set product variants order
 * @param {CartItemVariant[]} items - a list of product item variants
 * @param {string[]} [variantOrder] - allowed variants in desired order
 * @returns {CartItemVariant[]} sorted list of product variants
 * @category Utils
 */
export const sortVariants = (items: CartItemVariant[], variantOrder = ['color', 'size', 'width', 'length']) =>
  items.sort((a, b) => {
    const indexOfA = variantOrder.includes(a.code) ? variantOrder.indexOf(a.code) : Number.POSITIVE_INFINITY
    const indexOfB = variantOrder.includes(b.code) ? variantOrder.indexOf(b.code) : Number.POSITIVE_INFINITY
    return indexOfA - indexOfB
  })
